import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.lanueva
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.titulo}
        description={post.introHTML || post.introNotaHTML}
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
            }}
          >
            {post.titulo}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.fecha}
          </p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.textoHTML }} />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
        
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous._id} rel="prev">
                ← {previous.titulo}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next._id} rel="next">
                {next.titulo} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query($slug: String){
          site {
      siteMetadata {
        title
      }
    }
    lanueva(_id: {eq: $slug}) {
      _id
      titulo
      introHTML
      textoHTML
    }
  }

`
